import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const Media = () => {
  const data = useStaticQuery(graphql`
    query {
      mediaImage: file(relativePath: { eq: "paint-and-panel.png" }) {
        childImageSharp {
          fluid(maxWidth: 544) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Media coverage on DingGo" description="Media coverage about how DingGo has help Australians repair their cars." />
      <AnouncementBar />
      <div className="hero-banner">
        <div className="container">
          <h1 className="mb-4">DingGo Media Room</h1>
          <p>Find out the latest news, updates and information from DingGo. For media
                    inquiries contact <a target="_blank" rel="noreferrer" href="mailto:media@dinggo.com.au">Media@DingGo.com.au</a>
          </p>
        </div>
      </div>
      <div className="py-7 bg-light">
        <div className="container main-wrapper ">
          <div className="row mb-4">
            {[...Array(10)].map((x, i) => (
              <div className="col-6 mb-4">
                <div className="card shadow">
                  <div className="col-12">
                    <Img fluid={data.mediaImage.childImageSharp.fluid} />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">DingGo Wins 2019 Paint and Panel Innovation Award
                    </h5>

                    <p className="card-text">DingGo recognised by Smash Repair Industry with National Innovation Award</p>
                    <span className="">Read more</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Media;
